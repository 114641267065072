export enum ETypeHandle {
  // Entries
  PAGE = 'page',
  POST = 'post',
  EVENT = 'event',
  PERSON = 'person',
  SERVICE = 'service',
  AGREEMENT = 'agreement',
  ROOMPLANNING_REFERENCE = 'reference',
  OFFICE = 'office',
  OFFER = 'offer',
  BRAND = 'brand',
  LANDINGPAGE = 'landingpage',
  // Content
  CALL_TO_ACTION = 'callToAction',
  CONTENT_AGREEMENT_TILE_BLOCK = 'contentAgreementTileBlock',
  CONTENT_OFFER_TILE_BLOCK = 'contentOfferTileBlock',
  CONTENT_EVENT_DETAILED = 'contentEventDetailview',
  CONTENT_AUTOBLOCK_DYNAMIC = 'contentAutoblockDynamic',
  CONTENT_AUTOBLOCK_MANUAL = 'contentAutoblockManual',
  CONTENT_AUTOBLOCK_SIMPLE = 'contentAutoblockSimple',
  CONTENT_CIRCLE_TEXT = 'contentCircleTextWrapper',
  CONTENT_CIRCLE_TEXT_ITEM = 'contentCircleTextItem',
  CONTENT_CTA_CONTACT = 'callToActionContactpage',
  CONTENT_CTA_CONTACT_MINILU = 'callToActionContactpageMinilu',
  CONTENT_CTA_EVENT = 'contentCTAEvent',
  CONTENT_CTA_SERVICE = 'contentCTAService',
  CONTENT_CTA_OFFICE = 'contentCTAOffice',
  CONTENT_CTA_OFFER = 'contentCTAOffer',
  CONTENT_DOWNLOAD = 'contentDownload',
  CONTENT_DOWNLOAD_LIST = 'contentDownloadList',
  CONTENT_ENUMERATION = 'contentEnumeration',
  CONTENT_EVENT_ENUMERATION = 'contentEvents',
  CONTENT_IMAGE = 'contentImage',
  CONTENT_IMAGE_GALLERY = 'contentGallery',
  CONTENT_IMAGE_GALLERY_EXTENDED = 'contentGalleryExtended',
  CONTENT_ACCORDION_FAQS = 'contentAccordionFaqs',
  CONTENT_EVENT_INTRODUCTION = 'contentEventIntroduction',
  CONTENT_FORM = 'contentForm',
  CONTENT_FLOWCHARTBLOCK = 'contentFlowchartWrapper',
  CONTENT_FLOWCHARTITEM = 'contentFlowchartItem',
  CONTENT_HEADER_SIMPLE = 'contentHeaderSimple',
  CONTENT_HEADING = 'contentHeading',
  CONTENT_HIGHLIGHTBLOCK = 'contentHighlightblock',
  CONTENT_INTRODUCTION = 'contentIntroduction',
  CONTENT_LIST = 'contentList',
  CONTENT_LIST_OFFICE = 'contentListOffice',
  CONTENT_LIST_OFFER = 'contentListOffer',
  CONTENT_MAILCHIMP_PAYWALL = 'mailchimpPaywall',
  CONTENT_PERSON = 'contentPerson',
  CONTENT_QUOTE = 'contentQuote',
  CONTENT_PERSON_RATING_BLOCK = 'contentPersonRatingBlock',
  CONTENT_PERSON_RATING_ITEM = 'contentPersonRatingItem',
  CONTENT_POST_INTRODUCTION = 'contentPostIntroduction',
  CONTENT_PROCESS = 'contentProcessWrapper',
  CONTENT_PROCESS_ITEM = 'contentProcessItem',
  CONTENT_SERVICE_INTRODUCTION = 'contentServiceIntroduction',
  CONTENT_STYLED_SECTION = 'contentStyledSection',
  CONTENT_TEAM = 'contentTeam',
  CONTENT_TEXT = 'contentText',
  CONTENT_TEXT_IMAGE = 'contentTextImage',
  CONTENT_TEXT_IMAGE_TILE_BLOCK = 'contentTextImageTileBlock',
  CONTENT_TEXT_IMAGE_TILE_ITEM = 'contentTextImageTileItem',
  CONTENT_TOP_BANNER = 'contentTopbanner',
  CONTENT_TOP_BANNER_AREA_INTRO = 'contentAreaIntroTopbanner',
  CONTENT_TOP_SPEAKER = 'contentTopSpeaker',
  CONTENT_WYSIWYG_EDITOR = 'contentWysiwygEditor',
  CONTENT_CONTACT_REQUEST = 'contentContactRequest',
  CONTENT_CONTACT_REQUEST_MINILU = 'contentContactRequestMinilu',
  CONTENT_COMPARISON_TABLE = 'contentComparisonTable',
  CONTENT_BRANDS = 'contentBrandslider',
  CONTENT_GRID_TABLE = 'contentGridTableWrapper',
  CONTENT_GRID_TABLE_ITEM = 'contentGridTableItem',
  CONTENT_ROOMPLANNING_REFERENCES = 'contentRoomPReferences',
  CONTENT_TEASER = 'contentTeaser',
  CONTENT_FAQ_ADVANCED = 'contentFaqAdvanced',
  CONTENT_PERSONIO_JOBS = 'contentPersonioJobs',
  CONTENT_OFFICE_TABLE = 'contentOfficeTable',
  CONTENT_SEPARATOR = 'contentSeparator',
  CONTENT_NEWSLETTER_BANNER = 'newsletter',
  CME_POINTS = 'eventCmePoints',
  CONTENT_VIDEO = 'contentVideo',
  CONTENT_GLOSSARY = 'contentGlossary',
  BADGE = 'badge',
  PRODUUCT_RECOMMENDATIONS = 'produktRecommendations',
  CAMPAIGN_LIST = 'campaignList',
  MANUFACTURER_OVERVIEW = 'manufacturerOverview',
  MANUFACTURER_PRODUCT_LIST = 'manufacturerProductList',
  PRODUCT_TAG_PRODUCT_LIST = 'productTagProductList',
  PRODUCT_NUMBER_PRODUCT_LIST = 'productNumberProduceList',
  CUPO_ONLY_CONTAINER = 'cupoOnlyContainer',

  // Other
  PAGE_CONTENT_ROOT = 'pageContentRoot',
  TEMPLATE_EVENT_A = 'contentTemplateEvent_A',
  TEMPLATE_POST_A = 'contentTemplatePost_A',
  TEMPLATE_SERVICE_A = 'contentTemplateService_A',
  TEMPLATE_ROOMPLANNING_REFERENCE_A = 'contentTemplateRoomPRef_A',
  TEMPLATE_OFFICE_MARKET_A = 'contentTemplateOfficeMarket_A',
  TEMPLATE_PERSON = 'contentTemplatePerson',
  TEMPLATE_OFFER_A = 'contentTemplateOffer_A',
  UNSET = 'unset',

  // Non cms types
  PRINT_HEADER = 'printHeader',
  PRINT_FOOTER = 'printFooter',
}

export enum PageTypeHandle {
  PAGE = 'page',
  POST = 'post',
  EVENT = 'event',
  PERSON = 'person',
  SERVICE = 'service',
  AGREEMENT = 'agreement',
  ROOMPLANNING_REFERENCE = 'reference',
  OFFICE = 'office',
  OFFER = 'offer',
  TOP_BRAND = 'topBrandPage',
  TOP_BRAND_PREMIUM = 'premiumTopBrandsPage',
  TOP_BRAND_ROOT = 'topBrandRootPage',
  ACTIONS = 'actionPage',
  ACTION_OVERVIEW = 'actionOverviewPage',
  LANDINGPAGE = 'landingpage',
}
